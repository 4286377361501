.menuList {

  opacity: 1;
  transition: opacity 750ms ease-in-out;

  ul {
    list-style: none;
    margin: 0 0 128px;
    padding: 0;

    li {
      height: 62px;
      display: flex;
      align-items: stretch;
      font-size: 18px;
      letter-spacing: 0.64px;
      border-bottom: #333333 1px solid;
      font-weight: 400;
      &:first-child {
        border-top: #333333 1px solid;
      }

      div {
        display: flex;
        align-items: center;
        color: #666666;
      }

      div:first-child {
        width: 75px;
        flex-shrink: 0;
      }
      div:nth-child(2) {
        width: 100%;
      }
      div:nth-child(3) {
        flex-basis: 50%;
        flex-shrink: 0;
        @media (max-width: 767.98px) {
          display: none;
        }
      }

      span {

        width: 100%;
        display: flex;
        align-items: center;

        div {
          color: #FFF;
        }

        div:first-child {
          width: 75px;
          flex-shrink: 0;
        }
        div:nth-child(2) {
          width: 100%;
        }
        div:nth-child(3) {
          flex-basis: 50%;
          flex-shrink: 0;
          @media (max-width: 767.98px) {
            display: none;
          }
        }

      }


    }
  }
}

.hide {
  opacity: 0;
}
