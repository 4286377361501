.home {

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background-color: #fff;

  h1 {
    font-size: 72px;
    line-height: 112px;
    height: 112px;
    margin: 0;
    display: flex;
    align-items: center;

    @media (max-width: 767.98px) {
      font-size: 32px;
      line-height: 45px;
      letter-spacing: -0.25px;
    }

  }
}


