.background,
.menu {

  //position: relative;


  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;


  opacity: 1;
}


//Behind hero while closed
.menu {
  z-index: 1;

}

.background {

  z-index: 3;
  background-color: #000;
  transform: translateY(100%);
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}

.fade {
  opacity: 0;
}

// In front of hero when open
.open {
  //transform: translateY(0);
  z-index: 4;
  position: absolute;
}

.bgOpen {
  transform: translateY(0);
}

.moving {
  position: fixed !important;
}

.fixed {
  position: fixed !important;
}
