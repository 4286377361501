.menuLayout {
  padding: 200px 48px 1px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  color: #fff;
  opacity: 0;
  transition: opacity 750ms ease-in-out, padding-top 500ms ease-in-out;
  //transform: translateY(152px);
  position: fixed;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  overflow: hidden;
  height: 0;

  @media (max-width: 767.98px) {
    display: block;
    padding: 128px 24px 1px;
  }

}

.firstEnter {
  transition: none;
}

.changing {
  height: auto;
  position: absolute;
}


.active {
  //transition: opacity 1s ease-in-out, transform 500ms ease-in-out;
  opacity: 1;
  position: absolute;
  z-index: 4;
  display: flex;
  height: auto;
  @media (max-width: 767.98px) {
    display: block;
  }
}

.changing.active {
  transition: opacity 750ms ease-in-out 750ms, padding-top 500ms ease-in-out;
}

.scrollDown {
  //transform: translateY(0);
  padding-top: 48px;
  @media (max-width: 767.98px) {
    padding-top: 128px;
  }
}

.nav {
  flex-basis: 50%;
  position: fixed;
  z-index: 5;
  top: 48px;
  left: 48px;
  transition: transform 500ms ease-in-out;
  transform: translateY(152px);

  @media (max-width: 767.98px) {
    position: relative;
    transform: translateY(0);
    top: auto;
    left: auto;
    margin: 0 0 48px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      font-size: 36px;
      line-height: 60px;
      height: 60px;
      padding: 2px 0;
      //color: #666666;
      @media (max-width: 767.98px) {
        font-size: 30px;
        line-height: 46px;
        height: 50px;
      }
    }
  }
}

.scrollDownNav {
  transform: translateY(0);
}


//.hideNav {
//  opacity: 0;
//}

.list {
  flex-basis: 50%;
}
