//html {
//  overflow: hidden;
//  height: 100%;
//}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: $text-font;
  margin: 0;
  font-weight: 600;
  //overscroll-behavior-y: none;
}
