.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #FFF;
  z-index: 102;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(100%);
  transition: transform 500ms ease-in-out;
  height: 72px;
  padding: 0 48px;
  font-size: 18px;
  @media (max-width: 767.98px) {
    padding: 0 24px;
  }
}

.footerShow {
  transform: translateY(0);
}
.link {
  font-size: 18px;
  line-height: 38px;
  height: 38px;
}
