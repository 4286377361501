.link {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
  transition: color 300ms ease-in-out;
  &:after {
    height: 3px;
    display: block;
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
    content: "";
    background-color: #FFF;
    transition: transform 500ms ease-in-out, opacity 300ms ease-in-out 500ms;
    transform: translateX(-101%);
  }

  img {
    margin-right: 10px;
  }
}

.flex {
  display: flex;
  align-items: center;
}

.linkLg {
  margin: 0 13px;
  @media (max-width: 767.98px) {
    margin: 0 4px;
  }
  &:after {
    height: 10px;
    @media (max-width: 767.98px) {
      height: 5px;
    }
  }
}


.linkTell {
  &:after {
    height: 5px;
    @media (max-width: 767.98px) {
      height: 3px;
    }
  }
}

.linkMenu {
  &:after {
    height: 4px;
  }
}

.linkList {
  &:after {
    height: 1px;
  }
}

.gray{
  color: #666666;
  &:after {
    background-color: #666666;
  }
}
.black{
  color: #000;
  &:after {
    background-color: #000;
  }
}

.hover {
  &:after {
    transform: translateX(0%);
  }

}

.click {
  &:after {
    transform: translateX(101%);
    opacity: 0;
  }

}

.black {
  &:after {
    background-color: #000;
  }
}
