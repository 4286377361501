.header {
  height: 100px;
  display: flex;
  padding: 0 48px;
  align-items: center;
  justify-content: space-between;

  background-color: #FFF;

  z-index: 98;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(0);
  transition: transform 500ms ease-in-out;

  @media (max-width: 767.98px) {
    padding: 0 24px;
    height: 80px;
  }

}

.dark {
  background-color: #000;
}

.moving {
  background-color: transparent!important;
  mix-blend-mode: difference;
}

.logo {
  position: fixed;
  z-index: 99;
  height: 100px;
  width: 200px;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: translateY(0);
  transition: transform 500ms ease-in-out;

  @media (max-width: 767.98px) {
    left: 24px;
    height: 80px;
    width: 200px;
    margin: 0;
    justify-content: flex-start;
  }

}

.hide {
  transform: translateY(-100%);
}

.links {
  mix-blend-mode: difference;
  font-size: 18px;
  line-height: 38px;
  height: 38px;
  color: #FFF;

}

.show {
  @media (max-width: 767.98px) {
    display: none;
  }
}

.tell {
  @media (max-width: 767.98px) {
    margin-left: auto;
  }
}


