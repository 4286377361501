.tell {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
  z-index: 1;
  transform: translateY(100%);
  transition: transform 1s ease-in-out;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tellShow {
  z-index: 1000;
  transform: translateY(0);
}

.tellMove {
  z-index: 1000;
  transform: translateY(100%);
}

.header {
  height: 100px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 48px;
  font-size: 18px;
  @media (max-width: 767.98px) {
    height: 80px;
    padding: 0 24px;
  }
}

.link {
  font-size: 18px;
  line-height: 38px;
  height: 38px;
}

.tellLink {
  font-size: 48px;
  line-height: 92px;
  height: 92px;
  @media (max-width: 767.98px) {
    font-size: 24px;
    line-height: 46px;
  }
}
