@import "src/scss/variables";

.showFooter {
  display: none;

  @media (max-width: 767.98px) {

    z-index: 9;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $red;
    color: #FFF;
    font-size: 18px;
    letter-spacing: -0.14px;
  }
}
