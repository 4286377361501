.work {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  min-height: 100vh;

  background-color: #FFF;
  z-index: 1;

  transform: translateY(100vh);
  transition: transform 1s ease-in-out;


}



.active {
  z-index: 101;
  transform: none;
  position: absolute;
  bottom: auto;
  overflow-y: hidden;
}

.opening {
  position: fixed;

  transform: translateY(0);
}

.closing {
  position: fixed;
  transform: translateY(100vh);
  transition: transform 1s ease-in-out !important;
  z-index: 101;

}





.background {
  position: fixed;
  z-index: 1;
  opacity: 0;
  transition: opacity 500ms ease-in-out 500ms;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000;
}

.backgroundActive{
  z-index: 100;
  opacity: 1;
  transition: opacity 500ms ease-in-out;
}
.backgroundClosing {
  z-index: 100;
  transition: opacity 500ms ease-in-out 500ms;
  opacity: 0 !important;
}



hr {
  display: block;
  height: 48px;
  width: 100%;
  border: none;
  padding: 0;
  margin: 0;
  @media (max-width: 767.98px) {
    height: 24px;
  }
}



