@import "src/scss/variables";

.loader {
  position: fixed;
  z-index: 9999;
  height: 4px;
  top: 0;
  left: 0;
  right: 0;
  background-color: $red;

  animation-duration: 1.5s;
  animation-name: loading;
  animation-fill-mode: forwards;
}


@keyframes loading {
  0% {
    transform: translateX(-100%);
    opacity: 1;
  }

  66% {
    transform: translateX(0);
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
