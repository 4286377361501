@import "src/scss/variables";

.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  animation-delay: 2s;
  animation-duration: 1s;
  animation-name: loaderOut;
  animation-fill-mode: forwards;
}

.text {
  overflow: hidden;

  span {
    animation-duration: 2s;
    animation-name: textInOut;
    animation-fill-mode: forwards;
    display: block;
  }
}

@keyframes textInOut {
  0% {
    transform: translateY(100%);
  }

  33% {
    transform: translateY(0);
  }

  66% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
  }
}

@keyframes loaderOut {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
