
.body {
  padding: 100px 48px 120px;

  opacity: 1;
  transition: opacity 500ms ease-in-out;

  @media (max-width: 767.98px) {
    padding: 80px 24px 96px;
  }

  span {
    display: block;
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    max-width: 100%;
    height: auto;
    iframe {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }

  img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
  }

}

.bodyHide {
  opacity: 0;
}
