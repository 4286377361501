@import "src/scss/variables";
.logo {
  font-size: 28px;
  color: $red;
  margin: 0 auto;
  cursor: pointer;
  @media (max-width: 767.98px) {
    margin: 0;
  }
}
