.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #FFF;
  z-index: 102;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform: translateY(0);
  transition: transform 500ms ease-in-out;
  height: 100px;
  padding: 0 48px;
  font-size: 18px;
  @media (max-width: 767.98px) {
    height: 80px;
    padding: 0 24px;
  }
}

.HeaderHide {
  transform: translateY(-100%);
}

.link {
  font-size: 18px;
  line-height: 38px;
  height: 38px;
}
